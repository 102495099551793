import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + "/users";

class UserService {

    getAll(params) {
        return axios.get(API_URL, {
            params: params,
            headers: authHeader()
        });
    }

    get(id) {
        return axios.get(API_URL + '/' + id, { headers: authHeader() });
    }

    saveOrUpdate(user) {
        if (!user.id) {
            return axios.post(API_URL,
                {
                    nome: user.nome,
                    cliente_id: (user.cliente) ? user.cliente.id : null,
                    roles: user.roleNames,
                },
                { headers: authHeader() });
        }
        else {
            return axios.put(API_URL + '/' + user.id,
                {
                    nome: user.nome,
                    cliente_id: (user.cliente) ? user.cliente.id : null,
                    roles: user.roleNames,
                },
                { headers: authHeader() });
        }
    }

    delete(id) {
        return axios.delete(API_URL + '/' + id, { headers: authHeader() });
    }

    download(format) {
        return axios.get(API_URL + '/download/' + format, {
            responseType: 'arraybuffer',
            headers: authHeader()
        });
    }
}

export default new UserService();
